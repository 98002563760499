/*
	Name:				Core.js
	Description:        Core script for the theme.
	Version:            1.0.0
	Author:             Garrison Hughes
*/

(function ($) {
    $(function () {
        /**
         * body variable
         */
        const $body = $("body");

        /**
         * Hamburger toggle
         */
        const $mobileNav = $(".header.mobile");
        $(".controls__nav button").on("click touch", function () {
            $mobileNav.toggleClass("active");
        });

        /**
         * close mobile nav if user hits esc key
         */
        $(".controls__nav button").on("keydown", function (e) {
            if (e.key === "Escape") {
                $mobileNav.removeClass("active");
            }
        });

        /**
         * Carrot Down to activate submenu
         */
        $(".sub-menu-control").on("click touch", function (e) {
            // if screensize is xl than do nothing
            if (screenSize >= 1200) {
                return;
            }
            // if smaller than run the below
            e.preventDefault();
            e.stopPropagation();
            let item = $(e.currentTarget).parent();

            $(this)
                .parent()
                .siblings(".sub-menu")
                .slideToggle(function () {
                    $(this).toggleClass("sub-menu--open");
                    $(this).removeAttr("style");
                });
            $(this).toggleClass("sub-menu-control--open");
            item.toggleClass("active");
        });

        /**
         * Make first menu item click show submenu before navigating to new page.
         */
        $("#menu-main-menu li.menu-item-has-children > a, #menu-contact-menu li.menu-item-has-children > a").on("click touch", function (e) {
            const self = $(this);
            const href = self.attr("href");
            const doToggle = !href || href === "#";

            if (!self.is(".active") || doToggle) {
                e.preventDefault();
                // e.stopPropagation();
                self.toggleClass("active");

                $(this)
                    .siblings(".sub-menu")
                    .slideToggle(function () {
                        $(this).toggleClass("sub-menu--open");
                        $(this).removeAttr("style");
                    });

                $(this).children(".sub-menu-control").toggleClass("sub-menu-control--open");
            }
        });

        /**
         * Login Button Activates Portal Screen
         */
        $("#portal-btn").on("click touch", function (e) {
            e.stopPropagation();
            $(".portal-login-wrapper, .portal-container")
                .removeClass("hide")
                .delay(100)
                .queue(function () {
                    $(".portal-login-wrapper, .portal-container").addClass("active").dequeue();
                });

            //if active then prevent body from scrolling
            $body.addClass("overflow-hidden");
        });

        /**
         * Open sidebar form on request click of sub-menu item in contact menu
         */
        const $formFreightQuote = $(".c-sidebar-form--freight-quote");
        $(".form-frieght-quote a").on("click tap", function (e) {
            /** Prevent jump to top of page */
            e.preventDefault();
            e.stopPropagation();
            $formFreightQuote.toggleClass("active");

            //if active then prevent body from scrolling
            $body.addClass("overflow-hidden");
        });

        const $formRequestAnalysis = $(".c-sidebar-form--request-analysis");
        $(".form-request-analysis a").on("click tap", function (e) {
            /** Prevent jump to top of page */
            e.preventDefault();
            e.stopPropagation();
            $formRequestAnalysis.toggleClass("active");

            //if active then prevent body from scrolling
            $body.addClass("overflow-hidden");
        });

        /**
         * Close Items
         */
        $(document).on("click touch keyup keydown", function (e) {
            /**
             * Close Portal Login if usr clicks on background or esc key
             */
            if (e.target.matches(".portal-login-wrapper") || e.key === "Escape" || e.target.matches("span.portal-close i")) {
                $(".portal-login-wrapper, .portal-container")
                    .removeClass("active")
                    .delay(550)
                    .queue(function () {
                        $(".portal-login-wrapper, .portal-container").addClass("hide").dequeue();
                    });

                //allow body to scroll again
                $body.removeClass("overflow-hidden");
            }
            /**
             * Close sidebar form if active and user clicks elsewhere or esc key is hit
             */
            if (e.target.matches(".c-sidebar-form--freight-quote .c-sidebar-form__background") || e.key === "Escape") {
                $formFreightQuote.removeClass("active").delay(150);
                $body.removeClass("overflow-hidden");
            }

            if (e.target.matches(".c-sidebar-form--request-analysis .c-sidebar-form__background") || e.key === "Escape") {
                $formRequestAnalysis.removeClass("active").delay(150);
                $body.removeClass("overflow-hidden");
            }
        });
        /**
         * Onload get window size
         */
        let screenSize = $(window).outerWidth();
        let resizeTimer;
        /* if screen is larger or equal to 1200px set data screensize to xl */

        if (screenSize >= 1200) {
            $("body").data("screensize", "xl");
            $("header").addClass("header-fixed");
            /* if smaller remove data screensize */
        } else {
            $("body").removeData("screensize");
            $("header").removeClass("header-fixed");
        }

        $(window).on("resize", function (e) {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function () {
                // Run code here, resizing has "stopped"
                screenSize = $(window).outerWidth();
                /**
                 * show me the window width on resize
                 */

                if (screenSize >= 1200) {
                    $("body").data("screensize", "xl");
                    $("header").addClass("header-fixed");

                    // if user opened submenu on mobile and then resized to desktop remove classes that show submenu
                    $("#menu-main-menu li.menu-item-has-children > a, #menu-contact-menu li.menu-item-has-children > a").removeClass("active");
                    $(".sub-menu").removeClass("sub-menu--open");
                    $(".sub-menu-control").removeClass("sub-menu-control--open");
                } else {
                    $("body").removeData("screensize");
                    $("header").removeClass("header-fixed");
                }

                /**
                 * Update Employee Profile Repeater BG Image Width
                 */
                employeeProfileBgHeight($bgImgAngle);
                employeeProfileBgHeight($bgImgAngleFluid);
                /**
                 * Update Tech Slider Block BG Image Width
                 */
                employeeProfileBgHeight($bgImgAngleTech);
            }, 250);
        });

        /**
         * Add class to header on scroll
         */
        $(window).on("scroll", function () {
            if ($(window).scrollTop() >= 70) {
                $("#header").addClass("header-scroll");
            } else {
                $("#header").removeClass("header-scroll");
            }
        });

        /**
         * Grab all words within Masthead Header XL Or Fading Display Header
         */
        const $fadingMastheadHeaderXl = $(".has-masthead-header-xl-font-size");
        const $fadingHeader = $(".has-masthead-header-font-size");
        const $fadingDisplayHeader = $(".has-fading-display-header-font-size");

        function headerFade(headerText) {
            headerText.each(function () {
                const $this = $(this);
                const text = $this.html();
                const count = text.split(" ");
                const wordCount = count.length;
                // Get the inner HTML of the header
                //Wrap each word in a span tag
                const wrappedWords = text
                    .split(" ")
                    .map((word, i) => {
                        const space = i === wordCount - 1 ? " " : "";
                        let opacity = Math.pow(0.8, i);
                        const $wrappedWord = `<span style="opacity:${opacity}">${word} ${space}</span>`; // Wrap in a span
                        return $wrappedWord;
                    })
                    .join("");

                $this.html(wrappedWords); // Set the new HTML;
            });
        }

        /**
         * Check that element exist before running headerFade
         */
        $.each([$fadingMastheadHeaderXl, $fadingHeader, $fadingDisplayHeader], function (i, $el) {
            if ($el.length) {
                // Check if elements exist
                headerFade($el);
            }
        });

        /**
         * Get height of Employee Profile Repeater Block BG Img and set width equal to it to create a perfect triangle.
         */
        const $bgImgAngle = $(".c-employee-profile-repeater-block__bg-image--angle");
        const $bgImgAngleFluid = $(".c-employee-profile-repeater-block__bg-image--angle.bg-image--fluid");
        /**
         * Get height of Tech Slider Content Slider Block BG Img and set width equal to height
         */
        const $bgImgAngleTech = $(".c-tech-slider-block__bg-image--angle");

        function employeeProfileBgHeight(bgImg) {
            const height = bgImg.height();
            bgImg.css("width", height + "px");
        }
        employeeProfileBgHeight($bgImgAngle);
        employeeProfileBgHeight($bgImgAngleFluid);
        employeeProfileBgHeight($bgImgAngleTech);
    });

    /**
     * Carrier Schedule
     */
    const cells = $("td, th");

    cells
        .on("mouseover", function () {
            let el = $(this),
                pos = el.index();
            el.parent().find("th, td").addClass("hover");
            cells.filter(":nth-child(" + (pos + 1) + ")").addClass("hover");
        })
        .on("mouseout", function () {
            cells.removeClass("hover");
        });
})(jQuery);
